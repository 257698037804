
//@ts-nocheck
import Vue from "vue";
import {
	AdvertiserDataUpdate,
	Category,
} from "@/interfaces/advertiser";
import Alertize from "@/components/Alertize.vue";
import { Notification } from "@/interfaces/proccess";
import {
	isRequired,
	isNumber,
	isDomain,
	isMinLength,
	isMaxLength,
	isMaxCustomLength,
} from "@/services/rule-services";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import CardTextFieldToolTip from "@/components/Content/CardTextFieldToolTip.vue";
import { mapActions, mapGetters } from "vuex";
import { getError } from "@/utils/resolveObjectArray";
import { TypeLoading } from "@/interfaces/loading";
import CardActions from "@/components/Content/CardAction.vue";
import { isEmpty, isNull } from "lodash";
//@ts-check

const ACCOUNT = "rappi_amplify";
const MAKERS_NAMES_ENABLED = ["Otros", "Otro"];

export default Vue.extend({
	name: "AdvertiserCreate",
	props: {},
	components: {
		Alertize,
		CardTextField,
		CardAutocomplete,
		CardSwitch,
		CardTextFieldToolTip,
		CardActions,
	},
	data: () => ({
		title: "Edit",
		valid: false,
		loading: false,
		message: "",
		type: "info",
		show_tooltip_app_bundle: false,
		show_tooltip_domain: false,
		
		advertiser: {
			id: "",
			name: "",
			category_id: "",
			app_bundle: "",
			domain: "",
			active: true,
			external_id: undefined,
			maker_name: "",
    		country_id: 0,
			hasCampaign: false,
		} as AdvertiserDataUpdate,

		countries: [],
		makers: [],
		filters:{},

		control:{
			disabledCountry: false,
			disabledMakers: false,
			before: false
		},

		rules: {
			rulesCountry: [],
			rulesMakers:[]
		}
	}),
	created() {},
	async mounted() {
		try {
			await this.setLoadingData(TypeLoading.loading);
			await this.dispatchCategories();
			
			const advertiser: AdvertiserDataUpdate = await this.dispatchShowAdvertiser();
			this.setAdvertiser(advertiser);
			if(this.isAccountRappi){
				await this.fetchCountries();
				await this.fetchMakers(true);
			}
			await this.setLoadingData();
		} catch (error) {
			await this.setLoadingData();
			console.log("mounted", { error: error });
		}
		this.$refs.form.resetValidation();
	},
	computed: {
		...mapGetters("profile", ["isRolReport", "account"]),
		getID(): Number {
			return Number(this.$route.params.id);
		},
		getCategories(): Category[] {
			return this.$store.state.advertiser.categories;
		},
		getCountrys(){
			return this.countries;
		},
		getMarkers(){	
			return this.makers;
		},
		isAlertize(): Boolean {
			return this.$store.state.proccess.alertize;
		},
		isAccountRappi(){
			return this.account.account_type === ACCOUNT;
		},
		getRules() {
			return {
				isRequired,
				isNumber,
				isDomain,
				isMinLength,
				isMaxLength,
				isMaxCustomLength,
			};
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},
		isDisabledName(){
			return (this.advertiser.hasCampaign && this.isBefore) ? true : 
				!isEmpty(this.advertiser.maker_name) && 
				(!MAKERS_NAMES_ENABLED.includes(this.advertiser.maker_name))
		},
		isDisabledMakers(){
			return this.control.disabledMakers;
		},
		isDisabledCountry(){
			return this.control.disabledCountry;
		},
		isBefore(){
			return this.control.before;
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("person", ["fetchData"]),
		...mapActions("advertiser", ["makersList"]),
		...mapActions("proccess", ["setLoadingField"]),

		getError(index: any) {
			return getError(this.getErrors, index);
		},
		setAdvertiser(advertiser: AdvertiserDataUpdate) {
			this.control.before = advertiser.country_id > 0;
			this.advertiser = {
				id: advertiser.id,
				external_id: advertiser.external_id?.toString(),
				name: advertiser.name,
				category_id: advertiser.category_id,
				domain: advertiser.domain || "https://",
				app_bundle: advertiser.app_bundle,
				active: advertiser.active,
				hasCampaign: advertiser.has_campaigns,
				maker_name: this.isAccountRappi ? advertiser.maker_name : "",
				country_id: this.isAccountRappi 
							? advertiser.country_id > 0 
								? advertiser?.country?.numeric_code : null 
							: null
			} as AdvertiserDataUpdate;
			this.control.disabledCountry = this.isAccountRappi && this.advertiser.hasCampaign;
			this.control.disabledMakers = this.control.disabledCountry || !(this.advertiser.country_id > 0)
		},
		async validate() {
			let form = this.$refs.form;
			return await form.validate();
		},
		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{ root: true }
			);
		},
		async handleAction(action: any) {
			switch (action.type) {
				case "cancel":
					this.handleCancel();
					break;
			}
		},
		async handleSubmit() {
			try {
				await this.addRules();
				if (!(await this.validate())) return;
				await this.setLoadingData(TypeLoading.loading);
				await this.update();
				await this.setLoadingData();
			} catch (error) {
				await this.setLoadingData();
			}
		},
		handleCancel() {
			this.setNotification({ title: "", message: "", type: "" });
			this.$router.push({ name: "AdvertisersIndex" });
		},
		toggleTooltipAppBundle() {
			this.show_tooltip_app_bundle = !this.show_tooltip_app_bundle;
		},
		toggleTooltipDomain() {
			this.show_tooltip_domain = !this.show_tooltip_domain;
		},
		toggleStatus(status: boolean) {
			this.advertiser.active = Boolean(status);
		},
		async dispatchCategories() {
			return this.$store.dispatch("advertiser/getCategories", {
				root: true,
			});
		},
		async dispatchShowAdvertiser() {
			return this.$store.dispatch("advertiser/show", this.getID, {
				root: true,
			});
		},
		async fetchMakers(addItem: Boolean = false){
			if(!this.advertiser.country_id){
				return;
			}
			this.setLoadingField(true);
			this.filters.country_id = this.advertiser.country_id;
			await this.makersList({filters: this.filters})
			.then(async (resp) => {
				await this.parseDataMakers(resp, addItem);
				this.setLoadingField(false);
			})
			.catch(async (err) => {
				this.makers = [];
				this.setLoadingField(false);
			});
		},
		async fetchCountries(){
			let params = {
				type: "countries"
			};
			this.setLoadingField(true);
			await this.fetchData(params)
			.then(async (resp) => {
				await this.parseDataCountry(resp);
				this.setLoadingField(false);
			})
			.catch(async (err) => {
				this.setLoadingField(false);
			});
		},
		async parseDataMakers(makers: any, addItem: Boolean = false){
			this.makers = [];
			makers.forEach(c => {
				let {maker} = c;
				this.makers.push({id: maker, value: maker});
			});
			if(addItem){
				this.makers.push({id: this.advertiser.maker_name, value: this.advertiser.maker_name});
			}
		},
		async parseDataCountry(countries: any){
			countries.forEach(c => {
				let {title, items} = c;
				this.countries.push({header: title});
				items.forEach(i => {
					let {id, value} = i;
					this.countries.push({group: title, id: id, value: value});
				});
			});
		},
		async update() {
			return this.$store.dispatch(
				"advertiser/update",
				{
					advertiser: this.advertiser,
					id: this.getID,
				},
				{
					root: true,
				}
			);
		},
		async setData(value: string){
			this.advertiser.name = !isEmpty(value) 
						? MAKERS_NAMES_ENABLED.includes(this.advertiser.maker_name)
							? this.advertiser.name : value
						: "";
		},
		async refreshMakers(){
			this.advertiser.maker_name = "";
			this.advertiser.name = "";
			await this.fetchMakers();
		},
		async addRules(){
			if(this.isAccountRappi && this.isBefore){
				this.rules.rulesCountry = this.isDisabledCountry ? [] : [this.getRules.isRequired, this.getRules.isNumber];
				this.rules.rulesMakers = this.isDisabledCountry ? [] : [this.getRules.isRequired];
			}
		}
	},
	watch:{
		async "advertiser.maker_name"(old, val){
			if(this.isAccountRappi && this.isBefore){
				await this.setData(old);
			}
		},
		async "advertiser.country_id"(old, val){
			if(this.isAccountRappi && this.isBefore){
				if(val != 0 && old != val){
					await this.refreshMakers();
				}
				if(isNull(old)){
					this.makers = [];
					this.advertiser.maker_name = "";
					this.advertiser.name = "";
				}
				this.control.disabledMakers = this.control.disabledCountry || !(this.advertiser.country_id > 0);
			}
			
		}
	}
});
